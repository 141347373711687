import HttpService from './http-service.js';
import {OPSI_START_DAY} from "@/plugins/constant";

const httpService = new HttpService();

export default ({
    async getList(filter)
    {
        const response = await httpService.get(`BasePriceProfile`, filter);
        console.log(response);
        const result = {
            pageNumber: 0,
            perPage: 0,
            totalPage: 0,
            totalRow: 0,
            data: Array()
        };

        if (!response.status)
        {
            response.data = result;
            return response;
        }

        result.pageNumber = response.data.pageNumber;
        result.perPage = response.data.perPage;
        result.totalPage = response.data.totalPage;
        result.totalRow = response.data.totalRow;
        for(let i = 0; i < response.data.data.length; i++)
        {
            result.data.push(this._toObject(response.data.data[i]));
        }

        response.data = result;
        return response;
    },

    async getObject(id) {
        const response = await httpService.get(`BasePriceProfile/${id}`);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        response.data = this._toObject(response.data);
        return response;
    },

    async create(model) {
        model = this._setDefaultValue(model);
        const response = await httpService.post('BasePriceProfile', model);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        response.data = this._toObject(response.data);
        return response;
    },

    async update(model, id) {
        model = this._setDefaultValue(model);
        const response = await httpService.update('BasePriceProfile', id, model);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },

    async delete(id) {
        const response = await httpService.delete('BasePriceProfile', id);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },

    default() {
        return {
            id: 0,
            nama:'',

            startDay:0,
            startDayName:null,

            timeRangeId: 0,
            timeRangeNama: null,

            locationId: null,
            locationCode: null,

            unitId: 0,
            unitCode: null,

            currencyId: 0,
            currencyCode: null

        };
    },

    _setDefaultValue(jsonData) {
        if (jsonData === undefined || jsonData === null) {
            return this.default();
        }

        if (jsonData.timeRangeId === null || jsonData.timeRangeId === '') {
            jsonData.timeRangeId = 0;
        }

        if (jsonData.unitId === null || jsonData.unitId === '') {
            jsonData.unitId = 0;
        }

        if (jsonData.currencyId === null || jsonData.currencyId === '') {
            jsonData.currencyId = 0;
        }

        return jsonData;
    },

    _toObject(jsonData) {
        const result = this.default();
        result.id = jsonData.id;
        result.nama = jsonData.nama;

        result.startDay=jsonData.startDay;
        result.startDayName=this.getNameStartDay(result.startDay);

        result.timeRangeId= jsonData.timeRangeId;
        result.timeRangeNama= jsonData.timeRangeNama;

        result.locationId = jsonData.locationId;
        result.locationCode = jsonData.locationCode || '-';

        result.currencyId = jsonData.currencyId;
        result.currencyCode = jsonData.currencyCode;

        result.unitId = jsonData.unitId;
        result.unitCode = jsonData.unitCode;

        return result;
    },

    getNameStartDay(value){
        let result = '-';
        if (!(value === undefined || value === null || value === '' || value < 1)) {
            result = OPSI_START_DAY.filter((item)=>item.value === value)[0].label
        }
        return result;
    }
});