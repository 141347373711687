import HttpService from './http-service.js';
const httpService = new HttpService();

export default ({
    async getList(filter)
    {
        filter = '';
        console.log(filter)
        // const response = await httpService.get(`report-price-overseas`, filter);
        const dummyData = [
            {
                id: 1,
                spName: "Lion Group",
                iata: "SAW",
                basePriceName: "Platts CIF MED Italy",
                basePriceQty: 287.82,
                differential: 21.74,
                fuelPrice: 309.56,
                feeName: "Airport Fee (7.9 EUR/1000LT), Refinery Premium (USC/USG)",
                feeValue: 5.985,
                totalPrice: 315.55,
                currency: "USC",
                salesUnit: "USG",
                validityStartDate: "2024-04-01",
                validityEndDate: "2024-04-15"
            },
            {
                id: 2,
                spName: "Lion Group",
                iata: "SAW",
                basePriceName: "Platts CIF MED Italy",
                basePriceQty: 294.36,
                differential: 21.74,
                fuelPrice: 316.10,
                feeName: "Airport Fee (7.9 EUR/1000LT), Refinery Premium (USC/USG)",
                feeValue: 5.985,
                totalPrice: 322.09,
                currency: "USC",
                salesUnit: "USG",
                validityStartDate: "2024-04-16",
                validityEndDate: "2024-04-30"
            },
            {
                id: 3,
                spName: "Lion Group",
                iata: "MED",
                basePriceName: "Aramco Monthly",
                basePriceQty: 252.70,
                differential: 21.90,
                fuelPrice: 274.60,
                feeName: "Throughput Fee, Govt Fee, Flowage Fee",
                feeValue: 34.09,
                totalPrice: 309.59,
                currency: "USC",
                salesUnit: "USG",
                validityStartDate: "2024-04-01",
                validityEndDate: "2024-04-30"
            },
            {
                id: 4,
                spName: "Lion Group",
                iata: "RUH",
                basePriceName: "Aramco Monthly",
                basePriceQty: 248.70,
                differential: 31.90,
                fuelPrice: 280.60,
                feeName: "Throughput Fee, Govt Fee, Flowage Fee",
                feeValue: 13.613,
                totalPrice: 281.78,
                currency: "USC",
                salesUnit: "USG",
                validityStartDate: "2024-04-01",
                validityEndDate: "2024-04-30"
            },
            {
                id: 5,
                spName: "Lion Group",
                iata: "JED",
                basePriceName: "JAFO Posted Price",
                basePriceQty: 250.73,
                differential: 12.00,
                fuelPrice: 262.73,
                feeName: "Throughput Fee, Govt Fee, Flowage Fee",
                feeValue: 9.777,
                totalPrice: 272.51,
                currency: "USC",
                salesUnit: "USG",
                validityStartDate: "2024-04-01",
                validityEndDate: "2024-04-30"
            },
            {
                id: 6,
                spName: "Lion Group",
                iata: "DMK",
                basePriceName: "MOPS Prior Month",
                basePriceQty: 244.00,
                differential: 2.66,
                fuelPrice: 246.66,
                feeName: "BAFS Charge, Airport Fee",
                feeValue: 7.25,
                totalPrice: 253.91,
                currency: "USC",
                salesUnit: "USG",
                validityStartDate: "2024-04-01",
                validityEndDate: "2024-04-30"
            },
            {
                id: 7,
                spName: "Lion Group",
                iata: "BKK",
                basePriceName: "MOPS Prior Month",
                basePriceQty: 244.00,
                differential: 2.66,
                fuelPrice: 246.66,
                feeName: "Storage Service Fee",
                feeValue: 2.72,
                totalPrice: 252.35,
                currency: "USC",
                salesUnit: "USG",
                validityStartDate: "2024-04-01",
                validityEndDate: "2024-04-30"
            }
        ];

        const response = {
            status: true,
            data: {
                pageNumber: 1,
                perPage: 10,
                totalPage: 1,
                totalRow: dummyData.length,
                data: dummyData
            }
        };
        const result = {
            pageNumber: 0,
            perPage: 0,
            totalPage: 0,
            totalRow: 0,
            data: Array()
        };

        if (!response.status)
        {
            response.data = result;
            return response;
        }

        result.pageNumber = response.data.pageNumber;
        result.perPage = response.data.perPage;
        result.totalPage = response.data.totalPage;
        result.totalRow = response.data.totalRow;
        for(let i = 0; i < response.data.data.length; i++)
        {
            result.data.push(this._toObject(response.data.data[i]));
        }

        response.data = result;
        return response;
    },

    async getObject(id) {
        const response = await httpService.get(`report-price-overseas/${id}`);

        if (!response.status) {
            response.data = this.default();
            return response;
        }

        let data = response.data
        response.data = this._toObjectForEdit(data);
        return response;
    },

    async create(model) {
        model.locationId = model.locationId == null ? 0 : model.locationId;
        model.supplierId = model.supplierId == null ? 0 : model.supplierId;
        model.conversionRate = parseFloat(model.conversionRate) ? parseFloat(model.conversionRate) : null;
        const response = await httpService.post('report-price-overseas', model);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        response.data = this._toObject(response.data);
        return response;
    },


    default() {
        return {
            id: 0,
            spId: null,
            spName: null,
            iata: null,
            basePriceName: null,
            basePriceQty: null,
            differential:null,
            fuelPrice: null,
            feeName: null,
            feeValue: null,
            totalPrice: null,
            currency: null,
            salesUnit: null,
            validityStartDate: null,
            validityEndDate: null,
        };
    },

    _toObject(jsonData) {
        return {
            id: jsonData.id,
            spName: jsonData.spName,
            iata: jsonData.iata,
            basePriceName: jsonData.basePriceName,
            basePriceQty: jsonData.basePriceQty,
            differential: jsonData.differential,
            fuelPrice: jsonData.fuelPrice,
            feeName: jsonData.feeName,
            feeValue: jsonData.feeValue,
            totalPrice: jsonData.totalPrice,
            currency: jsonData.currency,
            salesUnit: jsonData.salesUnit,
            validityStartDate: jsonData.validityStartDate,
            validityEndDate: jsonData.validityEndDate,
        };
    },


    _toObjectForEdit(jsonData) {
        const result = this.default();
        result.id = jsonData.id;
        result.startDate = new Date(jsonData.startDate);
        result.endDate = new Date(jsonData.endDate);
        result.currencyFromId = jsonData.currencyFromId;
        result.currencyFromCode = jsonData.currencyFromCode;
        result.currencyToCode = jsonData.currencyToCode;
        result.currencyToId = jsonData.currencyToId;
        result.conversionRate = jsonData.conversionRate;
        result.supplierId = jsonData.supplierId;
        result.supplierCode = jsonData.supplierCode;
        result.supplierName = jsonData.supplierName;
        result.locationId = jsonData.locationId;
        result.locationCode = jsonData.locationCode;
        result.locationName = jsonData.locationName;
        result.source = jsonData.source;
        return result;
    }
});